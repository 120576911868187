import { SmlLoadingSpinner } from '~/components/ui/Progress/LoadingSpinner/LoadingSpinner';
import classNames from 'classnames';
import classes from './Button.module.css';
import utilClasses from '~/components/ui/Util/Util.module.css';
import { Link } from 'react-router-dom';
import { forwardRef, memo, useCallback } from 'react';

export const SML_BUTTON_VARIANTS = [
    'primary',
    'secondary',
    'tertiary',
    'destroy',
    'inline',
    'knob',
] as const;

/**
 * Menu emphasis e.g. medium is less present/visible than high and can be used
 * for secondary menus
 */
export const SML_BUTTON_EMPHASIS = ['high', 'medium'] as const;

const SML_BUTTON_VARIANT_CLASSES = {
    primary: classes.smlButtonPrimary,
    secondary: classes.smlButtonSecondary,
    tertiary: classes.smlButtonTertiary,
    destroy: classes.smlButtonDestroy,

    /**
     * Mimics same style as SML_MENU_VARIANTS.inline and is meant to be used together with an
     * icon
     */
    inline: classes.smlButtonInline,

    /**
     * Mimics same style as SML_MENU_VARIANTS.knob and is meant to be used together with an
     * icon
     */
    knob: classes.smlButtonKnob,
};

type Props = {
    children: React.ReactNode;

    /**
     * Set the visual appearance of the UI element
     */
    variant?: typeof SML_BUTTON_VARIANTS[number];

    /**
     * Optional icon rendered on the left of the button
     */
    iconLeft?: React.ReactNode;

    /**
     * Optional icon rendered on the right of the button
     */
    iconRight?: React.ReactNode;

    /**
     * Hide the label and only show the iconLeft
     */
    labelHidden?: boolean;

    /**
     * If true, shows a loading state
     */
    loading?: boolean;

    /**
     * Lower emphasis of secondary buttons
     */
    emphasis?: typeof SML_BUTTON_EMPHASIS[number];

    /**
     * Render button in a more compact format
     */
    compact?: boolean;

    /**
     * If true, the button will be sized to fit its content
     */
    fitContent?: boolean;
};

export type SmlButtonButtonProps = Props &
    React.ButtonHTMLAttributes<HTMLButtonElement>;

export type SmlButtonAnchorProps = Props &
    React.AnchorHTMLAttributes<HTMLAnchorElement>;

export type SmlButtonLabelProps = Props & React.ComponentPropsWithRef<'label'>;

/**
 * SmartLearn button component properties
 * TODO: with React 18, switch to `extends React.PropsWithChildren`
 */
export type SmlButtonProps =
    | SmlButtonButtonProps
    | SmlButtonAnchorProps
    | SmlButtonLabelProps;

/**
 * SmartLearn Button UI component
 */
export const SmlButton = memo(
    forwardRef<
        HTMLButtonElement | HTMLAnchorElement | HTMLLabelElement,
        SmlButtonProps
    >(
        (
            {
                children,
                className = '',
                variant = 'primary',
                iconLeft,
                iconRight,
                labelHidden,
                loading,
                emphasis = 'high',
                compact = false,
                fitContent = false,
                ...props
            }: SmlButtonProps,
            ref
        ): JSX.Element => {
            const defaultClassNames = [
                classes.smlButton,
                SML_BUTTON_VARIANT_CLASSES[variant],
            ];

            if (props?.onClick && isLabel(props)) {
                console.error(
                    'SmlButton: Button is rendered as a label, but `onClick` was defined.'
                );
            }

            const loadingStateValue = loading ? 'loading' : null;
            const clickInterceptor = useCallback(
                (
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent> &
                        React.MouseEvent<HTMLAnchorElement, MouseEvent> &
                        React.MouseEvent<HTMLLabelElement, MouseEvent>
                ) => {
                    if (loading) {
                        e.preventDefault();
                    }

                    if (!loading && props.onClick) {
                        props.onClick(e);
                    }
                },
                [loading, props]
            );

            className = classNames(
                defaultClassNames,
                {
                    [classes.smlButton_emphasis_medium]: emphasis === 'medium',
                    [classes.smlButton_compact]: compact,
                    [classes.smlButton_fitContent]: fitContent,
                },
                className
            );

            if (isButton(props)) {
                return (
                    <button
                        ref={ref as React.ForwardedRef<HTMLButtonElement>}
                        className={className}
                        type={props.type || 'button'}
                        data-state={loadingStateValue}
                        aria-busy={loading}
                        {...props}
                        onClick={props.onClick ? clickInterceptor : null}
                    >
                        {iconLeft && (
                            <span
                                className={classNames(
                                    classes.smlButtonIconLeft
                                )}
                            >
                                {iconLeft}
                            </span>
                        )}
                        <span
                            className={classNames({
                                [utilClasses.smlSrOnly]: labelHidden,
                            })}
                        >
                            {children}
                        </span>
                        {iconRight && iconRight}
                        {loading && (
                            <SmlLoadingSpinner
                                className={classes.smlButtonLoadingSpinner}
                            />
                        )}
                    </button>
                );
            } else if (isAnchor(props)) {
                const regex = new RegExp('^(https?://|blob:)', 'i');
                const isNotRoute = regex.test(props.href);
                const target = props.target || '_self';
                const rel = props.rel || 'noopener noreferrer';

                if (isNotRoute) {
                    return (
                        <a
                            ref={ref as React.ForwardedRef<HTMLAnchorElement>}
                            href={props.href}
                            target={target}
                            rel={rel}
                            className={className}
                            {...props}
                        >
                            {iconLeft && (
                                <span
                                    className={classNames(
                                        classes.smlButtonIconLeft
                                    )}
                                >
                                    {iconLeft}
                                </span>
                            )}
                            <span
                                className={classNames({
                                    [utilClasses.smlSrOnly]: labelHidden,
                                })}
                            >
                                {children}
                            </span>
                            {iconRight && (
                                <span
                                    className={classNames(
                                        classes.smlButtonIconRight
                                    )}
                                >
                                    {iconRight}
                                </span>
                            )}
                        </a>
                    );
                } else {
                    return (
                        <Link
                            ref={ref as React.ForwardedRef<HTMLAnchorElement>}
                            to={props.href}
                            target={target}
                            className={className}
                            {...props}
                        >
                            {iconLeft && (
                                <span
                                    className={classNames(
                                        classes.smlButtonIconLeft
                                    )}
                                >
                                    {iconLeft}
                                </span>
                            )}
                            <span
                                className={classNames({
                                    [utilClasses.smlSrOnly]: labelHidden,
                                })}
                            >
                                {children}
                            </span>
                            {iconRight && (
                                <span
                                    className={classNames(
                                        classes.smlButtonIconRight
                                    )}
                                >
                                    {iconRight}
                                </span>
                            )}
                        </Link>
                    );
                }
            } else if (isLabel(props)) {
                return (
                    <label
                        ref={ref as React.ForwardedRef<HTMLLabelElement>}
                        className={className}
                        data-state={loadingStateValue}
                        aria-busy={loading}
                        {...props}
                    >
                        {iconLeft && (
                            <span
                                className={classNames(
                                    classes.smlButtonIconLeft
                                )}
                            >
                                {iconLeft}
                            </span>
                        )}
                        <span
                            className={classNames({
                                [utilClasses.smlSrOnly]: labelHidden,
                            })}
                        >
                            {children}
                        </span>
                        {iconRight && iconRight}
                        {loading && (
                            <SmlLoadingSpinner
                                className={classes.smlButtonLoadingSpinner}
                            />
                        )}
                    </label>
                );
            }
        }
    )
);

function isButton(
    props:
        | React.ButtonHTMLAttributes<HTMLButtonElement>
        | React.AnchorHTMLAttributes<HTMLAnchorElement>
        | React.LabelHTMLAttributes<HTMLLabelElement>
): props is SmlButtonButtonProps {
    return !isAnchor(props) && !isLabel(props);
}

function isAnchor(
    props:
        | React.ButtonHTMLAttributes<HTMLButtonElement>
        | React.AnchorHTMLAttributes<HTMLAnchorElement>
        | React.LabelHTMLAttributes<HTMLLabelElement>
): props is SmlButtonAnchorProps {
    return 'href' in props;
}

function isLabel(
    props:
        | React.ButtonHTMLAttributes<HTMLButtonElement>
        | React.AnchorHTMLAttributes<HTMLAnchorElement>
        | React.LabelHTMLAttributes<HTMLLabelElement>
): props is SmlButtonLabelProps {
    return 'for' in props || 'htmlFor' in props;
}
