// There is a Vite built-in way to make type augmentation available globally, but due to the project
// currently is setup, this broke typescript type checking at the time of writing this code.
// so we manually type convert and return it as env.
export const env = (import.meta.env as unknown) as ImportMetaEnv;

export interface ImportMetaEnv {
    /**
     * e.g. v40.0.0, dev, dev-local
     */
    readonly VITE_APP_VERSION: string;

    /**
     * e.g. https://host.tld
     */
    readonly VITE_APP_BASE_URL: string;

    /**
     * e.g. https://host.tld/api
     */
    readonly VITE_API_BASE_URL: string;

    /**
     * e.g. /features
     */
    readonly VITE_FEATURES_API_ENDPOINT: string;

    /**
     * e.g. /graphql
     */
    readonly VITE_GRAPHQL_API_ENDPOINT: string;

    /**
     * e.g. /h5p
     */
    readonly VITE_H5P_API_ENDPOINT: string;

    /**
     * e.g. /login_check
     */
    readonly VITE_LOGIN_CHECK_API_ENDPOINT: string;

    /**
     * e.g. /logout
     */
    readonly VITE_LOGOUT_API_ENDPOINT: string;

    /**
     * e.g. /oauth/login/oidc/logout
     */
    readonly VITE_OICD_LOGOUT_ENDPOINT: string;

    /**
     * e.g. /performance_log
     */
    readonly VITE_PERFORMANCE_LOG_API_ENDPOINT: string;

    /**
     * e.g. /ping
     */
    readonly VITE_PING_API_ENDPOINT: string;

    /**
     * e.g. /machine/list_directory
     */
    readonly VITE_MACHINE_LIST_DIRECTORY_API_ENDPOINT: string;

    /**
     * e.g. /media_objects/ckeditor
     */
    readonly VITE_MEDIA_OBJECTS_CKEDITOR_API_ENDPOINT: string;

    /**
     * e.g. https://mercure.smartlearn.test
     * defaults to window.location.origin if empty
     */
    readonly VITE_MERCURE_BASE_URL: string;

    /**
     * e.g. /.well-known/mercure
     */
    readonly VITE_MERCURE_ENDPOINT: string;

    /**
     * Validity time of refresh token in seconds
     * e.g. 900
     */
    readonly VITE_REFRESH_TOKEN_EXPIRE_TIME: string;

    /**
     * e.g. /token/refresh
     */
    readonly VITE_REFRESH_TOKEN_API_ENDPOINT: string;
}
