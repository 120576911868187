import { TypedBlockConnection } from '../types/blocks-util';

/**
 * Extract blocks from a block connection.
 * @param blockConnection
 * @returns
 */
export function getBlocksFromBlockConnection<TBlockData>(
    blockConnection: TypedBlockConnection<TBlockData>
): TBlockData[] {
    return blockConnection.edges.reduce((acc, edge) => {
        if (!edge) {
            return acc;
        }

        return [...acc, edge.node];
    }, [] as TBlockData[]);
}
